import React from "react"
import { PlayButton, Timer, Progress } from "react-soundplayer/components"
import { withCustomAudio } from "react-soundplayer/addons"
import styled from "styled-components"

const PlayerStyles = styled.div`
  box-shadow: 0 0.4px 2.2px rgba(0, 0, 0, 0.02),
    0 0.9px 5.3px rgba(0, 0, 0, 0.028), 0 1.6px 10px rgba(0, 0, 0, 0.035),
    0 2.9px 17.9px rgba(0, 0, 0, 0.042), 0 5.4px 33.4px rgba(0, 0, 0, 0.05),
    0 13px 80px rgba(0, 0, 0, 0.07);
  width: 100%;
  max-width: 500px;
  margin: 5rem 1rem 0 1rem;
`

const Cover = styled.div`
  height: 15rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  display: grid;
  place-items: center;
`

const Play = styled(PlayButton)`
  background: none;
  border: 0 none;
  cursor: pointer;

  &:focus {
    outline: 0 none;
  }

  > svg {
    height: 40px;
    fill: #fff;
  }
`

const Title = styled.div`
  background: var(--body);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
  font-size: 1.8rem;
  padding: 2rem;

  display: flex;
  justify-content: space-between;

  .track-title {
    font-weight: 600;
  }
`

const Player = withCustomAudio(props => {
  const { bg, track, category, duration, currentTime } = props

  return (
    <PlayerStyles className="audio-player">
      <Cover
        style={{
          background: `url(${bg}) no-repeat center center/ cover`,
        }}
      >
        <Play {...props} />
      </Cover>
      <Title>
        <span className="track-title">
          {track} ({category})
        </span>
        <Timer {...props} />
      </Title>
      <Progress value={(currentTime / duration) * 100 || 0} {...props} />
    </PlayerStyles>
  )
})

export default Player
