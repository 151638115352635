import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ButtonStyle = styled.button`
  border: 2px solid var(--mint);
  border-radius: 30px;
  cursor: pointer;
  padding: 1.5rem 2.5rem;
  background-color: var(--body);
  color: white;
  font-family: "Montserrat";
  font-weight: 600;
  text-transform: lowercase;
  font-size: 1.6rem;
  width: max-content;
  transition: all 0.1s ease;

  :hover {
    background: var(--mint);
    color: #fff;
  }
`

const Button = ({ link, children }) => {
  if (link) {
    return (
      <Link to={link} style={{ margin: "3rem 0" }}>
        <ButtonStyle>{children}</ButtonStyle>
      </Link>
    )
  }

  return (
    <ButtonStyle type="submit" style={{ margin: "3rem 0" }}>
      {children}
    </ButtonStyle>
  )
}

export default Button
