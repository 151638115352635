import React from "react"
import styled from "styled-components"

import Header from "../components/Header"
import Footer from "../components/Footer"
import Feedback from "../components/Feedback"
import SEO from "../components/SEO"

import { Section, Wrapper } from "../components/styles/Container"
import GlobalStyles from "../components/styles/GlobalStyles"
import Button from "../components/styles/Button"

import Player from "../components/Player"
import TrackOne from "../audio/metal.mp3"
import TrackTwo from "../audio/remix.mp3"
import TrackThree from "../audio/rock.mp3"

import Abstract from "../img/abstract.jpg"
import AbstractTwo from "../img/abstract2.jpg"
import AbstractThree from "../img/abstract3.jpg"

const Spacer = styled.div`
  margin-top: 7rem;
  /* Width in PX > 768px */
  @media only screen and (min-width: 48em) {
    margin-top: 15rem;
  }
  h2 {
    margin-bottom: 3rem;
  }
`

const Paragraph = styled.p`
  max-width: 900px;
  /* Width in PX > 920px */
  @media only screen and (min-width: 62em) {
    text-align: center;
  }
`

const Intro = styled.div`
  margin-bottom: 5rem;
  h2 {
    margin-bottom: 2rem;
  }
`

const App = () => {
  return (
    <div className="app">
      <GlobalStyles />
      <SEO title="Home" />
      <Header />
      <Wrapper>
        <Section>
          <Intro className="flex vertical v-center">
            <h2
              className="glitch"
              data-text="Mixing & Mastering Remix & Production"
            >
              Mixing & Mastering <br /> Remix & Production
            </h2>
            <Paragraph>
              Hi! Do you want to bring your music to the next level? Qualix
              might be the right choice for this job, offering you quality
              mixing, mastering and music production services with high
              attention to detail and individuality for fair prices. Convince
              yourself and have a listen!
            </Paragraph>
          </Intro>

          <div className="flex vertical v-center l-horizontal between">
            <Player
              bg={Abstract}
              track="example song"
              category="Rock&Pop"
              streamUrl={TrackThree}
            />
            <Player
              bg={AbstractTwo}
              track="example song"
              category="Metal"
              streamUrl={TrackOne}
            />
            <Player
              bg={AbstractThree}
              track="example song"
              category="Remix&Production"
              streamUrl={TrackTwo}
            />
          </div>
        </Section>

        <Section>
          <Spacer className="flex vertical v-center">
            <h2 className="glitch" data-text="Mix and Mastering">
              Mix and Mastering
            </h2>

            <Paragraph>
              Use this if you have individual audio tracks that need to be mixed
              into a release-ready master.
            </Paragraph>
            <Button link="/customize"> Nice, let's see</Button>
          </Spacer>
          <Spacer className="flex vertical v-center">
            <h2 className="glitch" data-text="Mastering">
              Mastering
            </h2>
            <Paragraph>
              Use this if you have a finished mix that needs to be mastered.
            </Paragraph>
            <Button link="/customize_mastering"> Nice, let's see</Button>
          </Spacer>
          <Spacer className="flex vertical v-center">
            <h2 className="glitch" data-text="Remix and production">
              Remix and production
            </h2>
            <Paragraph>
              Use this if you have a finished song that you want to have remixed
              or if you have an acapella vocal track that needs an instrumental.
              You can also order just an instrumental created with some
              references.
            </Paragraph>
            <Button link="/customize_production"> Nice, let's see</Button>
          </Spacer>
          <Spacer className="flex vertical v-center">
            <h2 className="glitch" data-text=" About Qualix">
              About Qualix
            </h2>
            <Paragraph>
              Qualix was founded in 2020 by Dorian Quael as a one man company in
              Germany. His vision is offering professional quality audio
              engineering services independently and worldwide for fair prices.
            </Paragraph>
            <Button link="/about"> Get to know me</Button>
          </Spacer>
        </Section>
      </Wrapper>
      <Feedback></Feedback>
      <Footer />
    </div>
  )
}

export default App
