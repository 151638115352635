import React from "react"
import styled from "styled-components"

import { Wrapper } from "./styles/Container"
import "./styles/klaus-gridski.css"

const FeedbackStyle = styled.div`
  background-color: var(--mint);
  padding: 7rem 0;
  margin-top: 10rem;
`

const FeedbackHeadline = styled.h3`
  color: var(--body);
  text-align: center;
`

const Quote = styled.div`
  p:before {
    content: open-quote;
    display: block;
    color: var(--font-color);
    font-size: 3rem;
    font-family: "Montserrat";
    font-weight: bold;
    padding: 0 1rem 0 0;
  }

  p {
    color: var(--body);
    display: flex;
    padding: 2.5rem 0;
  }

  p:after {
    content: close-quote;
    display: block;
    color: var(--font-color);
    font-size: 3rem;
    font-family: "Montserrat";
    font-weight: bold;
    padding: 0 0 0 1rem;
  }
  max-width: 750px;
`

const Feedback = () => {
  return (
    <FeedbackStyle>
      <Wrapper className="flex v-center vertical">
        <FeedbackHeadline>some nice reading matters</FeedbackHeadline>
        <Quote>
          <p>
            Everything I send him, it turns out to sound amazing! ….. In
            Hipsterism, he made my breakdown sound djenty even though the drums
            stem that I gave him was an average preset. 110% recommended!
          </p>
          <p>
            Great communicator and also is an awesome artist. He pays attention
            to detail of client's needs and get it done. Thank you for your
            work. Will return.
          </p>
          <p>
            Dorians attention to detail was great. He wanted the best from the
            track and worked with me to get the most out of it. The job was done
            in good time and a couple of minor revisions were done quickly and
            well. Good job all round, I recommend him!
          </p>
        </Quote>
      </Wrapper>
    </FeedbackStyle>
  )
}

export default Feedback
